import { initPhoneMask } from './components/phone-number-form/initPhoneMask';

document.addEventListener('DOMContentLoaded', function () {
    initPhoneMask();
});

window.addEventListener('load', function () {
    const script = document.querySelector('[data-href-style]');
    if (!script) return;

    createAndAppendStyleLink(script.dataset.hrefStyle);

    function createAndAppendStyleLink(href) {
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = href;
        document.head.appendChild(link);
    }
});
